<template>
	<div class="email-template-setting">
		<v-layout class="py-2">
			<v-flex class="">
				<div class="listing-heading">Email Template</div>
			</v-flex>
			<v-spacer></v-spacer>
			<v-btn color="grey lighten-3" tile depressed class="mx-3" @click="goBack()">
				<v-icon small left>mdi-arrow-left</v-icon>
				Back
			</v-btn>
		</v-layout>
		<template v-if="email_templates.length > 0">
			<div class="border pb-5" style="max-height: calc(100vh - 155px); overflow-y: scroll">
				<div
					class="pa-3"
					v-for="(row, index) in email_templates"
					:key="index"
					:class="{ 'border-bottom': email_templates[index + 1] }"
				>
					<div class="d-flex align-items-center py-2">
						<div class="fw-600 mr-5">{{ row.template }}</div>
						<div>
							<v-switch
								v-model="row.activated"
								inset
								hide-details
								class="my-0"
								color="green"
								v-on:change="updateStatusEmailTemplateSetting(row.id, $event)"
							></v-switch>
						</div>
						<div>
							<v-chip
								v-for="(auth, aindex) in row.authorized"
								label
								:key="aindex"
								class="mr-2"
								:class="`mail-auth-${auth} text-capitalize`"
								>{{ auth }}</v-chip
							>
						</div>
					</div>
					<div class="d-flex align-items-center py-2">
						<div class="fw-500 mr-5">{{ row.description }}</div>
						<v-spacer></v-spacer>
						<div class="d-flex justify-content-end">
							<v-btn
								color="blue darken-4 white--text"
								tile
								depressed
								v-on:click="$router.push({ name: 'email-template-update', params: { id: row.id } })"
								>Edit</v-btn
							>
						</div>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>
<script>
import { GetEmailTemplates, updateEmailTemplateStatus } from "@/core/lib/setting.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
// import { filter } from "lodash";

export default {
	name: "EmailTemplate",
	data() {
		return {
			email_templates: new Array(),
		};
	},
	methods: {
		getEmailTemplate() {
			const _this = this;
			GetEmailTemplates()
				.then((data) => {
					_this.email_templates = data;
				})
				.catch((error) => {
					_this.goBack();
					_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		updateStatusEmailTemplateSetting(template, status) {
			const _this = this;
			updateEmailTemplateStatus(template, +!!status)
				.then(() => {
					_this.getEmailTemplate();
				})
				.catch((error) => {
					_this.goBack();
					_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
	},
	mounted() {
		this.getEmailTemplate();
	},
};
</script>
