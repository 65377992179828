<template>
	<div class="w-100">
		<div>
			<v-btn
				:loading="value ? pageLoading || uploading : false"
				class="white--text avatar-change-btn"
				color="blue darken-4"
				icon
				large
				v-on:click="changeImage()"
				v-if="canChange"
				:disabled="pageLoading || uploading"
			>
				<v-icon v-if="value" large>mdi-camera-retake</v-icon>
				<v-icon v-else large>mdi-camera-plus</v-icon>
			</v-btn>
			<v-file-input
				:disabled="uploading"
				v-on:change="updateFile($event)"
				class="d-none"
				ref="avatar-input"
				accept="image/png, image/jpeg"
			></v-file-input>
			<div v-if="value">
				<v-img height="100%" :src="getImagePath()" :lazy-src="getImagePath()"> </v-img>
			</div>
			<div
				v-else
				v-on:click="changeImage()"
				class="py-4 ma-1 cursor-pointer d-flex justify-content-center align-items-center"
				style="border: 2px dashed #d5d4d4"
			>
				<div>
					<div>
						<template v-if="pageLoading || uploading">
							<v-icon size="60" color="grey lighten-1" class="mdi-spin">mdi-sync</v-icon>
						</template>
						<template v-else>
							<v-icon size="60" color="grey lighten-1">mdi-cloud-upload-outline</v-icon>
						</template>
					</div>
					<span class="fw-600 fs-18 text-muted"> UPLOAD </span>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { head } from "lodash";
import { UploadFiles } from "@/core/lib/upload.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
export default {
	name: "v-avatar-update",
	props: {
		pageLoading: {
			type: Boolean,
			default: false,
		},
		canChange: {
			type: Boolean,
			default: false,
		},
		value: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			profile: null,
			uploading: false,
		};
	},
	methods: {
		getImagePath() {
			if (this.value) {
				return this.value;
			}
			return process.env.VUE_APP_BASE_URL + "/media/misc/no_photo_found.png";
		},
		changeImage() {
			const avatar_ref = this.$refs["avatar-input"];
			if (avatar_ref) {
				avatar_ref.$refs["input"].click();
			}
		},
		updateFile(file) {
			if (file) {
				this.uploading = true;
				const formData = new FormData();
				formData.append("files[0][file]", file);
				formData.append("files[0][name]", file.name.split(".").slice(0, -1).join("."));
				formData.append("files[0][suffix]", `.${file.name.split(".").pop()}`);
				UploadFiles(formData)
					.then((response) => {
						const profile = head(response);
						if (profile.url) {
							this.$emit("input", profile.url);
						}
					})
					.catch((error) => {
						this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
					})
					.finally(() => {
						this.uploading = false;
					});
			}
		},
	},
};
</script>
